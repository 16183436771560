<template>
  <div class="users">
    <UserListFilter :is-downloading="isDownloading" @get-data="getData" @download="download" />
    <b-table
      ref="table"
      striped
      hover
      show-empty
      small
      :items="tableDataProvider"
      :fields="fields"
      :busy="isLoading"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #cell(id)="data">
        <router-link :to="{name: 'UserShow', params: { id: data.item.id }}">
          {{ data.item.id }}
        </router-link>
      </template>

      <template #cell(email)="data">
        <div class="text-nowrap">
          {{ data.item.email }}
          <b-icon-check-circle v-if="data.item.email_confirmed" />
        </div>
        <div class="text-nowrap">
          {{ data.item.googleEmail ? data.item.googleEmail : data.item.is_google ? '-' : '' }}
          <b-img
            v-if="data.item.is_google"
            :src="require('@/assets/google-icon.svg')"
            width="16px"
            class="ml-1 pb-1"
          />
        </div>
        <div class="text-nowrap">
          {{ data.item.appleEmail ? data.item.appleEmail : data.item.is_apple ? '-' : '' }}
          <b-img
            v-if="data.item.is_apple"
            :src="require('@/assets/apple-icon.svg')"
            width="16px"
            class="ml-1 pb-1"
          />
        </div>
        <div v-if="!data.item.email && !data.item.googleEmail && !data.item.appleEmail && !data.item.is_apple && !data.item.is_google">
          {{ '-' }}
        </div>
      </template>
      <template #cell(paid)="data">
        <b-icon-check v-if="data.item.paid" scale="1.75" />
      </template>
      <template #cell(app)="data">
        <small>
          Стор: {{ data.value.store }}<br>
          Язык: {{ data.value.lang }}<br>
          Версия: {{ data.value.version }}
        </small>
      </template>
      <template #cell(created_at)="data">
        {{ new Date(data.item.created_at).toLocaleString("ru") || "–" }}
      </template>

      <template #cell(status)="data">
        {{ SUBSCRIBE_STATUSES[data.item.status ]|| "–" }}
      </template>

      <template #cell(promo)="data">
        {{ getPromoName(data.item.promo) }}
      </template>

      <template #cell(subscriptionType)="data">
        <div v-if="new Date(data.item.subscriptionExpDate) < (new Date())" style="color:grey;">
          {{ data.item.subscriptionType || '-' }}
          <div>
            <small>
              {{ data.item.subscriptionExpDate }}
            </small>
          </div>
        </div>
        <div v-else>
          {{ data.item.subscriptionType || '-' }}
          <div>
            <small>
              {{ data.item.subscriptionExpDate }}
            </small>
          </div>
        </div>
      </template>

      <template #cell(devices)="data">
        <b-badge :variant="data.value.length > 4 ? 'danger' : ''">
          {{ data.value.length }}
        </b-badge>
        <div v-for="(hash, index) in data.value" :key="index" :class="{'test-device': $store.getters.getTestHashes.includes(hash), hash: true} ">
          {{ hash }}
        </div>
      </template>

      <template #cell(controls)="data">
        <b-button
          variant="danger"
          size="sm"
          @click="toggleRemove(data.item.id)"
        >
          <b-icon-trash-fill aria-hidden="true" />
        </b-button>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong class="ml-3">Загрузка данных...</strong>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="users.length"
      v-model="currentPage"
      align="center"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="my-table"
      @change="changePage"
    />
  </div>
</template>

<script>
import UserListFilter from '@/components/UserListFilter'
import { SUBSCRIBE_STATUSES } from '@/constants'
export default {
  name: 'Users',
  components: {
    UserListFilter,
  },
  data() {
    return {
      totalRows: 1,
      perPage: 20,
      currentPage: 1,
      isLoading: false,
      isDownloading: false,
      query: '',
      SUBSCRIBE_STATUSES,
      users: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
          sortable: true,
        },
        {
          key: 'app_user_id',
          label: 'RevenueCat ID',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'email',
          label: 'E-mail',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Телефон',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'devices',
          label: 'Устройства',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'paid',
          label: 'Оплата',
          sortable: true,
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          key: 'status',
          label: 'Подписка',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
          sortable: true,
        },
        {
          key: 'promo',
          label: 'Промокод',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
          sortable: false,
        },
        {
          key: 'subscriptionType',
          label: 'Тип подписки',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
          sortable: true,
        },
        {
          key: 'created_at',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
          label: 'Зарегистрирован',
          sortable: true,
        },
        {
          key: 'app',
          label: 'Приложение',
          thClass: 'text-center align-middle',
          tdClass: '',
        },
        {
          key: 'controls',
          label: 'Действия',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
        },
      ],
      sortBy: 'id',
      sortDesc: true,
    }
  },
  watch: {
    currentPage() {
      // this.getData();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page
    }
    if (this.$route.query.limit) {
      this.perPage = this.$route.query.limit
    }
    this.$store.dispatch('queryTestDevices')
  },
  methods: {
    changePage(page) {
      //this.$route.query.page = page;
      this.$router.replace(
        {
          query: Object.assign({ ...this.$route.query }, { page: page }),
        },
        () => {},
      )
    },
    getData() {
      this.currentPage = 1
      this.$refs.table.refresh()
    },
    tableDataProvider(ctx) {
     this.query = this.$route.fullPath.split('?')[1]
      this.query = this.query ? '&' + this.query : ''
      if (this.$route.query.page) {
        ctx.currentPage = this.$route.query.page
        this.currentPage = this.$route.query.page
      }
      if (this.$route.query.limit) {
        ctx.perPage = this.$route.query.limit
        this.perPage = this.$route.query.limit
      }
      return this.axios
        .get(
          process.env.VUE_APP_BACKEND_URL +
            '/admin/api/users/?page=' +
            ctx.currentPage +
            '&limit=' +
            ctx.perPage +
            '&sort=' +
            this.sortBy +
            '&sort_dir=' +
            (this.sortDesc ? 'desc' : 'asc') +
            this.query,
            {...this.$store.getters.getAxiosConfig, params: {test_devices: this.$store.getters.getTestHashes}},
        )
        .then((response) => {
          this.users = response.data.users
          this.totalRows = response.data.count
          return this.users || []
        })
        .catch((error) => {
          console.log(error)
          this.users = []
          this.totalRows = 0
          return this.users || []
        })
    },
    async download() {
      try {
        this.isDownloading = true
        const response = await this.axios
        .get(
          process.env.VUE_APP_BACKEND_URL +
            '/admin/api/users/?download=1' +
            '&sort=' +
            this.sortBy +
            '&sort_dir=' +
            (this.sortDesc ? 'desc' : 'asc') +
            this.query,
             {...this.$store.getters.getAxiosConfig, params: {test_devices: this.$store.getters.getTestHashes}, responseType: 'blob'},
        )

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        const fileName = `users-${new Date().toLocaleString(['ru-RU'])}.xlsx`

        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.isDownloading = false
      } catch (error) {
        this.isDownloading = false
        console.log('error: ', error)
      }
    },
    toggleRemove(id) {
      if (
        confirm(
          'Удалить пользователя #' +
            id +
            '?\nВНИМАНИЕ!!! БУДЕТ УДАЛЕНА ВСЯ ИНФОРМАЦИЯ О ПОЛЬЗОВАТЕЛЕ',
        )
      ) {
        // this.isLoading = true
        this.axios
          .delete(
            process.env.VUE_APP_BACKEND_URL + '/admin/api/users/' + id,
            this.$store.getters.getAxiosConfig,
          )
          .then((response) => {
            console.debug(response)
            this.getData()
            // this.isLoading = false;
          })
          .catch((error) => {
            console.error('🐹', error)
            this.$root.$emit('global-error')
            // this.isLoading = false;
          })
      }
    },
    getPromoName(promoArray) {
      if (promoArray[0] && promoArray[0].titles && promoArray[0].titles.length > 0) {
        return promoArray[0].titles.join(', ')
      }
      return ''
    },
  },
}
</script>

<style scoped>
  .users {
    color: #555;
    font-size: 0.9rem;
  }

  .hash {
    color: rgb(63, 62, 62);
    font-size: 0.9rem;
    padding-top: 3px;
    white-space: nowrap;
  }

  .hash:not(:last-child) {
    border-bottom: 1px solid #dddddd;
  }
  .test-device {
    color: #007BFF;
    font-weight: 500;
  }
</style>
