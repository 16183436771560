<template>
  <FilterTemplate
    v-model="form"
    :is-downloading="isDownloading"
    @on-submit="onSubmit"
    @clear-filters="clearFilters"
  >
    <template v-slot:form-items>
      <b-row class="grid grid-cols-5 gap-4">
        <b-col>
          <b-form-group label="RevenueCat ID:">
            <b-input v-model="form.revenue_cat_id" size="sm" />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Hash устройства:">
            <b-input v-model="form.device_hash" size="sm" title="Введите 'тест' для поиска тестовых хешей" />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Email:">
            <b-input v-model="form.email" size="sm" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Телефон:">
            <b-input v-model="form.phone" size="sm" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Оплачено:">
            <b-select v-model="form.paid" size="sm" :options="PAID_STATUSES" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Статус подписки:">
            <b-select
              v-model="form.status"
              size="sm"
              :options="STATUSES"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="grid grid-cols-5 gap-4 mt-2">
        <b-col>
          <b-form-group label="Регистрация от:">
            <b-input v-model="form.registration_from" size="sm" type="date" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Регистрация до:">
            <b-input v-model="form.registration_to" size="sm" type="date" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Устройств от:">
            <b-input v-model="form.devices_from" size="sm" type="number" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Устройств до:">
            <b-input v-model="form.devices_to" size="sm" type="number" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Промокод:">
            <b-input v-model="form.promoName" size="sm" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Стор:">
            <b-input v-model="form.appStore" size="sm" />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </FilterTemplate>
</template>

<script>
import FilterTemplate from '@/components/FilterTemplate.vue'
import {STATUSES, PAID_STATUSES } from '@/constants'

export default {
  components: {
    FilterTemplate,
  },
  props: {
    isDownloading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    category_entity: null,
    form: {
      revenue_cat_id: null,
      registration_from: null,
      registration_to: null,
      devices_from: null,
      devices_to: null,
      device_hash: null,
      email: null,
      phone: null,
      paid: null,
      status: null,
      promoName: null,
      appStore: null,
    },
    PAID_STATUSES,
    STATUSES,
  }),
  computed: {},
  watch: {},
  methods: {
    onSubmit(params) {
      this.$emit('get-data', params)
    },
    clearFilters() {
      this.category_entity = null
      this.$emit('get-data')
    },
  },
}
</script>
