<template>
  <b-card class="relative p-2.5 mb-3 bg-gray-100 border border-gray-300" title="Фильтр">
    <!-- form -->
    <b-form class="base-filter-template" @submit.prevent="onSubmit">
      <!-- form-items (input/textarea/select) -->
      <slot name="form-items" />
      <!-- /form-items (input/textarea/select) -->

      <!-- btns -->
      <div class="mt-3">
        <b-button
          type="submit"
          class="mr-2"
        >
          Найти
        </b-button>
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="onClear"
        >
          Сбросить
        </b-button>
        <LoadingButton
          variant="outline-secondary"
          text="Выгрузить"
          type="button"
          :is-busy="isDownloading"
          @click.native="$parent.$emit('download')"
        />
      </div>
      <!-- /btns -->
    </b-form>
    <!-- /form -->
  </b-card>
</template>

<script>
import LoadingButton from '@/components/LoadingButton'

  export default {
    name: 'FilterTemplate',
    components: {
      LoadingButton,
    },
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
      isDownloading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        fields: {},
      }
    },
    watch: {
      value(val) {
        this.fields = val
      },
    },
    mounted() {
      this.fields = this.value
      this.getFieldsData()
    },
    methods: {
      getFieldsData() {
        Object.entries(this.fields)
          .forEach(([key]) => {
            this.fields[key] = this.$route.query[key] ?? null
          })
      },
      onSubmit() {
        let params = {}
        Object.entries(this.fields).filter((e) => e[1] !== null)
          .forEach(([key,value]) => {params[key] = value})
        this.$router.push({path: this.$route.fullPath.split('?')[0], query: params}).catch(e => e)
        this.$emit('on-submit', params)
      },
      onClear() {
        Object.keys(this.fields).forEach(key => {
          this.fields[key] = null
        })
        this.$router.push({path: this.$route.fullPath.split('?')[0]}).catch(e => e)
        this.$emit('input', this.fields)
        this.$emit('clear-filters')
      },
    },
  }
</script>
<style>
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(212,212,212,var(--tw-border-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245,245,245,var(--tw-bg-opacity));
}
.border {
  border-width: 1px;
}
</style>
