export const PROMO_DURATIONS = [
  { value: 'daily', text: '24 hour access' },
  { value: 'weekly', text: '7 day access' },
  { value: 'monthly', text: '1 month (31-day) access' },
  { value: 'two_month', text: '2 month (61-day) access' },
  { value: 'three_month', text: '3 month (92-day) access' },
  { value: 'six_month', text: '6 month (183-day) access' },
  { value: 'yearly', text: '1 year (365-day) access' },
  { value: 'lifetime', text: '200 year access' },
]

export const PROMO_ENTITLEMENTS = [{ value: 'premium', text: 'premium' }]

export const SUBSCRIBE_STATUSES = {
  expired: 'Закончилась',
  active: 'Активна',
  renewed: 'Продлена',
  trial: 'Пробная',
}

export const STATUSES = [
  { value: 'null', text: 'Любой' },
  { value: 'trial', text: SUBSCRIBE_STATUSES.trial },
  { value: 'renewed', text: SUBSCRIBE_STATUSES.renewed },
  { value: 'active', text: SUBSCRIBE_STATUSES.active },
  { value: 'expired', text: SUBSCRIBE_STATUSES.expired },
]

export const PAID_STATUSES = [
  { value: null, text: 'Любой' },
  { value: true, text: 'Оплачен' },
  { value: false, text: 'Неоплачен' },
]

